$font-paralucent: paralucent-stencil, sans-serif;
$font-facit: jaf-facitweb, sans-serif;

$font-heading-l: 3rem;
$font-heading-s: 2.25rem;

$font-paragraph-l: 1.25rem;
$font-paragraph-m: 1.12rem;
$font-paragraph-s: 1rem;
$font-paragraph-xs: 0.75rem;
