$breakpoints: (
  'small'  : ( max-width:  767px ),
  'medium' : ( min-width: 768px ) and ( max-width:  1067px ),
  'medium-and-below' : ( max-width:  1067px ),
  'medium-and-above' : ( min-width: 768px ),
  'large'  : ( min-width: 1068px )
);

@mixin breakpoint($name) {
  // If the key exists in the map
  @if map-has-key($breakpoints, $name) {
    // Prints a media query based on the value
    @media #{inspect(map-get($breakpoints, $name))} {
      @content;
    }
  }

  // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$name}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
  }
}
