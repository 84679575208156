@import 'stylesheets/variables';

.app-Hero {
  align-items: center;
  background-size: cover;
  display: flex;
  margin: 60px 0 10px;
  position: relative;

  @include breakpoint(small) {
    margin-top: 10px;
  }

  h3 {
    text-align: left;
  }

  &-text {
    font-size: 0.8em;
    padding-left: 30px;
    width: 60%;

    @include breakpoint(small) {
      padding: 0 15px;

      width: 100%;

      p {
        font-size: 1em;
      }
    }

    p {
      font-size: 1.1em;
    }

    hr {
      border: 1px solid $brown;
    }
  }

  &-icon {
    display: block;
    flex-grow: 1;

    @include breakpoint(small) {
      display: none;
    }

    img {
      bottom: -5px;
      margin-left: auto;
      margin-right: auto;
      max-width: 250px;
      position: absolute;
      right: -10px;
    }
  }
}
