@import 'stylesheets/variables';

.app-Header {
  &-top {
    color: $white;
    display: flex;
    flex-flow: row;
    margin: 0 auto;
    max-width: 1145px;
    position: relative;

    @include breakpoint(small) {
      flex-flow: column;
    };
  }

  &-nav {
    > div {
      background-color: $brown;
      display: flex;
      flex-flow: row;
      margin: 0 auto;
      max-width: 1145px;
      position: relative;

      @include breakpoint(small) {
        display: none;
      };
    }
  }

  %base-link {
    border: 0;
    color: $white;
    cursor: pointer;
    flex-grow: 1;
    font-size: $font-paragraph-s;
    outline: none;
    padding: 0.75em 0.2em;
    text-align: center;
    text-decoration: none;
  }

  &-link {
    @extend %base-link;
    background-color: $brown;

    &-active {
      @extend %base-link;
      background-color: darken($brown, 5%);
    }
  }

  &-container {
    background-attachment: fixed;
    background-image: url('../../assets/header.jpg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;
    padding: 10px 30px 30px;
    text-align: center;
    width: 100%;

    @include breakpoint(small) {
      padding: 30px;
      width: calc(100% - 60px);
    }

    > h1 {
      margin: 10px 0;
    }

    > h3 {
      margin-top: 0;
    }

    > button {
      width: 300px;
    }
  }

  &-logo {
    margin: 15px auto 0;
    position: relative;
    width: 75px;

    > img {
      left: 0;
      position: absolute;
      top: 0;
      width: 78px;
    }
  }
}
