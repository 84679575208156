@import 'stylesheets/variables';

.app-Snippet {
  margin: 0 auto;
  max-width: 480px;

  h3 {
    text-align: left;
  }
}
