@import 'stylesheets/variables';

.app-Input {
  margin: 0 0 5px;

  > input {
    width: calc(100% - 12px);
  }

  &-label {
    border-bottom: 1px solid $brown;
    color: $brown;
    font-size: 0.8em;

    &-disabled {
      @extend .app-Input-label;
      border-bottom: 1px solid $dark-gray;
      color: $dark-gray;
    }
  }

  &-field {
    border: 1px solid lighten($brown, 20%);
    font-size: 1em;
    height: 46px;
    margin-top: 5px;
    padding: 0 5px;
    text-align: center;
    transition: all 0.15s ease-in-out;

    &-disabled {
      @extend .app-Input-field;
      border: 1px dashed $dark-gray;
      background-color: $light-gray;
    }

    &:focus {
      border: 1px solid $brown;
      outline: none;
    }
  }
}
