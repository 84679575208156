@import 'stylesheets/variables';

%base {
  border: 0;
  cursor: pointer;
  font-size: $font-paragraph-s;
  outline: none;
  padding: 0.75em 2em;
  transition: filter 0.2s linear;
  height: 48px;
  box-sizing: border-box;
  width: 100%;
  margin: 5px 0;

  &:hover:enabled {
    filter: drop-shadow($shadow-filter);
  }

  &:disabled {
    cursor: default;
  }
}

.app-Button {
  &-primary {
    @extend %base;
    background-color: $brown;
    color: $white;

    &:active {
      background-color: darken($brown, 5%);
    }

    &:disabled {
      background-color: darken($light-gray, 20%);
    }
  }

  &-secondary {
    @extend %base;
    background-color: darken($white, 5%);
    color: $black;

    &:active {
      background-color: darken($white, 5%);
    }
  }

  &-tertiary {
    @extend %base;
    background-color: transparent;
    border: 1px solid $white;
    color: $white;
    flex-grow: 1;
    transition: all 0.15s ease-in-out;

    &:hover {
      background-color: $white;
      color: $black;
      filter: none;
    }
  }
}
