@import 'stylesheets/variables';

%base {
    display: inline-block;
    border-top: 1px solid lighten($brown, 20%);
    border-bottom: 1px solid lighten($brown, 20%);
    max-width: calc(100% - 0.2em);
    margin: 5px 0;
    color: $dark-gray;
    font-size: 0.8em;
    padding: 0.2em;
    width: 100%;
}
.app-Note {
    @extend %base;

    &-warning {
        @extend %base;

        border: 2px solid $yellow;
        text-align: center;
        color: $black;
        background-color: lighten($yellow, 30%);
        max-width: calc(100% - 0.4em - 4px);
    }
}