@import 'stylesheets/variables';

.app-Announcement {
  align-items: center;
  display: flex;
  margin: 60px 0 10px;
  position: relative;
  color: $white;
  height: 500px;

  @include breakpoint(small) {
    margin-top: 10px;
  }
  &-heading {
    font-size: 0.8em;
    margin: 0 auto;

    @include breakpoint(small) {
      width: 100%;
    }

    h2 {
      margin-bottom: 5px;
    }

    hr {
      border: 1px solid $brown;
      margin-bottom: 24px;
    }
  }
}
