@import 'stylesheets/variables';

.app-Panel {
  background-color: #FFF;
  box-shadow: $shadow;
  margin: 0 auto;
  max-width: 1145px;
  position: relative;

  &-container {
    display: flex;
    flex-flow: row;

    @include breakpoint(small) {
      flex-flow: column;
    };
  }

  &-content {
    padding: 10px 30px 30px;
    width: calc(100% - 60px);

    @include breakpoint(small) {
      order: 2;
      padding: 10px;
      width: calc(100% - 20px);
    }
  }

  &-sidebar {
    background-color: darken($white, 3%);
    color: $black;
    display: flex;
    flex-flow: column;
    margin: 0 auto;
    overflow: hidden;
    width: 300px;

    @include breakpoint(small) {
      margin-top: 50px;
      max-width: 480px;
      width: calc(100% - 10px);
    }

    iframe {
      margin-bottom: -5px;
      margin-left: 10px;
      margin-right: 10px;
      width: calc(100% - 20px);
    }

    &-link {
      background-color: transparent;
      border: 1px solid $brown;
      color: $brown;
      cursor: pointer;
      display: block;
      width: calc(100% - 20px - 0.4em);
      flex-grow: 1;
      font-size: $font-paragraph-s;
      margin: 10px;
      outline: none;
      padding: 0.75em 0.2em;
      text-align: center;
      text-decoration: none;
      transition: all 0.15s ease-in-out;
      font-weight: 400;

      &:hover {
        background-color: $brown;
        color: $white;
      }
    }

    &-button {
      background-color: transparent;
      border: 1px solid $brown;
      color: $brown;
      cursor: pointer;
      display: block;
      flex-grow: 1;
      font-size: $font-paragraph-s;
      margin: 10px;
      outline: none;
      padding: 0.75em 0.2em;
      text-align: center;
      text-decoration: none;
      transition: all 0.15s ease-in-out;
      width: calc(100% - 20px);
      height: 48px;
      box-sizing: border-box;
      font-weight: 400;

      &:hover {
        background-color: $brown;
        color: $white;
      }
    }

    &-auth {
      border-bottom: 1px solid $brown;
      color: $brown;
      display: inline-block;
      font-size: 1em;
      margin: 10px 0 auto;
    }
  }

}
