@import 'stylesheets/variables';

html {
  background-color: $brown;
  height: 100%;
}

body {
  background-image: url('assets/wood.jpg');
  background-repeat: repeat;
  font-family: $font-facit;
  font-size: $font-paragraph-m;
  font-weight: 200;
  margin: 0;
  min-height: calc(100vh - 80px);
  padding-bottom: 80px;
  position: relative;
}

h1 {
  font-family: $font-paralucent;
  font-size: $font-heading-l;
  font-weight: 300;
  text-align: center;
}

h2 {
  font-family: $font-paralucent;
  font-size: $font-heading-s;
  font-weight: 300;
}

h3 {
  font-family: $font-paralucent;
  font-size: $font-paragraph-l;
  font-weight: 300;
  text-align: center;
}

b {
  font-weight: 400;
}

.app {
  margin: 0 2em;

  @include breakpoint(small) {
    margin: 0;
  };
}

.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

#root {
  height: 100%;
  padding-top: 2em;

  @include breakpoint(small) {
    padding-top: 0;
  };
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 36px;
  display: none;

  @include breakpoint(small) {
    display: block;
  };
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: $light-gray;
  box-shadow: $shadow-menu;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: $light-gray;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: $light-gray;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: $brown;
  padding: 2.5em 00;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: $brown;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0;
  max-height: calc(100% - 2.5em);

  /* Individual Links */
  .app-Header-link {
    display: inline-block;
    border: 0;
    color: $white;
    width: calc(100% - 2em);
    cursor: pointer;
    flex-grow: 1;
    font-size: $font-paragraph-l;
    font-weight: 500;
    outline: none;
    padding: 0.75em 1em;
    text-decoration: none;
  }
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
