@import 'stylesheets/variables';

.app-SignIn {
  background-color: $light-gray;
  font-size: 0.8em;
  margin: 10px auto;
  max-width: calc(100% - 20px);
  padding: 0 10px;
  text-align: left;

  @include breakpoint(small) {
    margin: 0 auto;
    max-width: 450px;
  };

  &-button {
    width: 100%;
  }

  &-inputGroup {
    border-top: 1px solid $brown;
    text-align: center;
  }

  &-link {
    background-color: transparent;
    border: 1px solid $brown;
    color: $brown;
    cursor: pointer;
    display: block;
    flex-grow: 1;
    font-size: $font-paragraph-s;
    margin-bottom: 10px;
    outline: none;
    padding: 0.75em 0.2em;
    text-align: center;
    text-decoration: none;
    transition: all 0.15s ease-in-out;
    font-weight: 400;

    &:hover {
      background-color: $brown;
      color: $white;
    }
  }

  &-status {
    border-top: 1px solid $brown;
    margin: 10px 0;
    padding: 5px;
  }
}
