@import 'stylesheets/variables';

%base {
  margin: 0 auto;
}

.app-Text {
  &-default {
    @extend %base;
    max-width: 480px;
  }

  &-wide {
    @extend %base;
    max-width: 720px;
  }
}
