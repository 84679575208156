@import 'stylesheets/variables';

%base {
  background-color: $white;
  padding: 2.5em 1em 2em 1em;
  margin: 2.5em auto;
  position: relative;
  display: block;
  overflow: hidden;
  border: #DDD 2px solid;
}

.app-Wizard {
  @extend %base;
  flex: 1 300px;

  &-grow {
    @extend %base;
    flex: 2 300px;
  }

  &-title {
    position: absolute;
    left: 0;
    top: 0;
    text-align: center;
    padding: 0.2em 0;
    width: 100%;
    background-color: #DDD;
    font-weight: 400;
  }

  &-back {
    position: absolute;
    height: 26px;
    font-weight: 600;
    left: 0;
    top: -3px;
    margin-left: 2px;
    text-align: center;
    justify-content: center;
    width: 26px;
    padding: 0;

    img {
      height: 27px;
    }
  }
}