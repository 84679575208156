$sizes: (
  'xxxs': 5px,
  'xxs': 12px,
  'xs': 15px,
  's': 20px,
  'm': 25px,
  'l': 30px,
  'xl': 35px,
  'xxl': 40px,
);

%base {
  display: flex;
}

.SpaceBetween {
  @extend %base;

  &-horizontal {
    flex-direction: row;
    flex-wrap: wrap;
  }

  &-vertical {
    flex-direction: column;
  }

  @each $name, $size in $sizes {
    &-#{$name} {
      gap: $size;
    }
  }

  &-align-center {
    align-items: center;
    justify-content: center;
  }

  &-align-start {
    align-items: start;
  }

  &-align-end {
    align-items: end;
  }
}
